import React, {forwardRef, lazy, Suspense, useCallback, useMemo} from 'react';
import PropTypes from 'prop-types';
import {defaultComparator} from '@ag-grid-community/core/dist/esm/es6/utils/generic';
import localeText from '../../elements/AgGrid/locale.bg.js';
import {agMultiFilter, agParseDate, agSort, paymentMethods} from '../../../utils.js';
import SmallSpinner from '../../elements/Spinner/SmallSpinner.jsx';
import {format} from "date-fns";
import useBrokerVoucherCommission from "../../../hooks/useBrokerVoucherCommission.js";
import useUsersVoucherCommission from "../../../hooks/useUsersVoucherCommission.js";
import DetailsIcon from "../../elements/Icons/DetailsIcon.jsx";
import PolicyInfoButton from "../NavigationButtons/PolicyInfoButton.jsx";
import PremiumAmount from "../../elements/Values/PremiumAmount.jsx";
import useCurrentUserHasRole from "../../../hooks/useCurrentUserHasRole.js";
import {useGetCurrentUserQuery} from "../../../features/apiSlice.js";
import DateShort from "../../elements/DateTime/DateShort.jsx";
import InfoIcon from "../../elements/Icons/InfoIcon.jsx";

const AgGridEnterprise = lazy(() => import('../../elements/AgGrid/AgGridEnterprise.js'));
const AgGridReact = lazy(() => import('../../elements/AgGrid/AgGridReactWrapper'));

const  PaymentGridWidget = forwardRef(({
    rowData,
}, ref) => {

    const showCommission = useCurrentUserHasRole('ROLE_SHOW_COMMISSION');

    const {
        data: user,
    } = useGetCurrentUserQuery(undefined, {
        skip: !showCommission,
    });

    const {getBrokerVoucherCommissionAmount} = useBrokerVoucherCommission(user);
    const {getFilteredUsers,getUserVoucherCommissionAmount} = useUsersVoucherCommission();

    const filteredUsers = useCallback( voucher => getFilteredUsers(voucher), [getFilteredUsers]);

    const getFirstInstallment = useCallback(voucher => (voucher?.installments || []).concat().shift(), []);
    const getPolicy = useCallback(voucher => getFirstInstallment(voucher)?.policy, [getFirstInstallment]);
    const getInstallmentNumbers = useCallback(
        voucher => (voucher?.installments || []).map(installment => installment.number).join(','),
        []
    );

    const animateRows = true;
    const parseDate = useCallback((date, resetTime) => agParseDate(date, resetTime), []);

    const onGridReady = useCallback(event => agSort(event, [{
        colId: 'issueDate',
        sort: 'asc',
    }]), []);

    const premiumColumnDefs = useMemo(() => ({
        aggFunc: 'sum',
        cellRenderer: params => <PremiumAmount amount={params.value || 0} />,
        filter: 'agNumberColumnFilter',
        type: 'rightAligned',
        width: 118,
    }), []);

    const excelStyles = useMemo(() => [{
        id: 'stringType',
        dataType: 'String',
    }, {
        id: 'dateTimeType',
        dataType: 'DateTime',
        numberFormat: {
            format: 'dd.mm.yyyy'
        }
    }], []);

    const multiFilter = agMultiFilter;

    const columnDefs = useMemo(() => [{
        headerName: 'Общо',
        field: 'totalAmount',
        ...premiumColumnDefs,
    },{
        headerName: 'Корекция',
        field: 'correctionAmount',
        ...premiumColumnDefs,
    },...(showCommission ? [{
        colId: 'commissionAmount',
        headerName: 'Комисион',
        valueGetter: params => params.data ? getBrokerVoucherCommissionAmount(params.data, getPolicy(params.data)) : null,
        ...premiumColumnDefs,
    }] : []),{
        colId: 'userCommissionAmount',
        headerName: 'Консултант комисион',
        valueGetter: params => params.data &&
            getUserVoucherCommissionAmount(params.data, filteredUsers(params.data)[0]),
        ...premiumColumnDefs,
    },{
        colId: 'stored',
        headerName: 'Отчетено',
        filter: 'agDateColumnFilter',
        value: params => params.data?.storeDate,
        valueGetter: params => parseDate(params.data?.storeDate, true),
        cellRenderer: params => params.data?.storeDate ? <>
            <DateShort date={params.data.storeDate} html />{' '}
            {(params.data.storageUser || params.data.storageOffice) &&
                <InfoIcon onClick={() =>
                    alert([params.data.storageUser?.shortName, params.data.storageOffice?.name].join(' - '))
                } />}
        </> : 'Не',
        cellClass: 'dateTimeType',
    },{
        colId: 'policy.number',
        filter: 'agTextColumnFilter',
        headerName: 'Полица №',
        valueGetter: params => params.data ? getPolicy(params.data).number : null,
        cellRenderer: params => params.data ?
            <PolicyInfoButton policy={getPolicy(params.data)} small /> : params.value,
        width: 221,
        cellClass: 'stringType',
    },{
        colId: 'ЗК',
        headerName: 'ЗК',
        valueGetter: params => params.data ? getPolicy(params.data).insuranceCompany.shortName : null,
        width: 75,
        ...multiFilter,
    },{
        colId: 'policy.productName',
        headerName: 'Продукт',
        valueGetter: params => params.data ? getPolicy(params.data).productName : null,
        cellRenderer: params => <>
            {params.value}{' '}<DetailsIcon details={params.data?.details} />
        </>,
        ...multiFilter,
    },{
        colId: 'installments',
        headerName: 'Вноска',
        valueGetter: params => params.data ? getInstallmentNumbers(params.data) + '/' +
            getPolicy(params.data).installmentsCount : null,
        width: 60,
    },{
        colId: 'issueDate.week',
        filter: 'agNumberColumnFilter',
        headerName: 'Седм',
        valueGetter: params => params.data ? parseInt(format(new Date(params.data.issueDate), 'I')) : null,
        width: 75,
    },{
        colId: 'issueDate',
        filter: 'agDateColumnFilter',
        headerName: 'Дата',
        valueGetter: params => params.data?.issueDate,
        cellRenderer: params => <DateShort date={parseDate(params.data?.issueDate, false)} />,
        width: 150,
        cellClass: 'dateTimeType'
    },{
        colId: 'paymentMethod',
        headerName: 'Плащане',
        valueGetter: params => params.data ? paymentMethods[params.data.paymentMethod] : null,
        ...multiFilter,
    }, {
        colId: 'policyIssueUser',
        headerName: 'Издал полица',
        valueGetter: params => params.data ? getPolicy(params.data).issueUser?.shortName: null,
        ...multiFilter,
    },{
        headerName: 'Консултант',
        field: 'issueUser.shortName',
        ...multiFilter,
    },{
        headerName: 'Комисион за',
        field: 'commissionUser.shortName',
        cellRenderer: params => <>{filteredUsers(params.data)[0]?.shortName}</>,
        ...multiFilter,
    },{
        headerName: 'Офис',
        field: 'issueOffice.name',
        ...multiFilter,
    }, {
        colId: 'policy.insuranceCompany.shortName',
        headerName: 'Застрахователна компания',
        valueGetter: params => params.data ? getPolicy(params.data).insuranceCompany.shortName : null,
        width: 251,
        ...multiFilter,
    },{
        headerName: 'към Брокер',
        field: 'paidPremiumFromClient',
        valueGetter: params => params.data ? (params.data.paidPremiumFromClient ? 'Отчетена' : 'Неотчетена') : null,

    },{
        colId: 'paidPremiumFromClientDate',
        filter: 'agDateColumnFilter',
        headerName: 'Дата към Брокер',
        valueGetter: params => parseDate(params.data?.paidPremiumFromClientDate, false),
        cellRenderer: params => <DateShort date={params.value} />,
        width: 150,
    },{
        headerName: 'към ЗК',
        field: 'paidPremiumToInsuranceCompany',
        valueGetter: params => params.data ? (params.data.paidPremiumToInsuranceCompany ? 'Отчетена' : 'Неотчетена') : null,

    },{
        colId: 'paidPremiumToInsuranceCompanyDate',
        filter: 'agDateColumnFilter',
        headerName: 'Дата към ЗК',
        valueGetter: params => parseDate(params.data?.paidPremiumToInsuranceCompanyDate, false),
        cellRenderer: params => <DateShort date={params.value} />,
        width: 150,
    },{
        headerName: 'на Брокер',
        field: 'paidCommissionFromInsuranceCompany',
        valueGetter: params => params.data ? (
            params.data.paidPolicyCommissionFromInsuranceCompany && params.data.paidVoucherCommissionFromInsuranceCompany ?
                'Изплатена' : 'Неизплатена'
        ) : null,

    },{
        colId: 'paidCommissionFromInsuranceCompanyDate',
        filter: 'agDateColumnFilter',
        headerName: 'Дата на Брокер',
        valueGetter: params => parseDate(params.data?.paidCommissionFromInsuranceCompanyDate, false),
        cellRenderer: params => <DateShort date={params.value} />,
        width: 150,
    },{
        headerName: 'на Консултант',
        field: 'paidCommissionToAgent',
        valueGetter: params => params.data ? (
            params.data.paidPolicyCommissionToAgent && params.data.paidVoucherCommissionToAgent ?
                'Изплатена' : 'Неизплатена'
        ) : null,
    },{
        colId: 'paidCommissionToAgentDate',
        filter: 'agDateColumnFilter',
        headerName: 'Дата на Консултант',
        valueGetter: params => parseDate(params.data?.paidCommissionToAgentDate, false),
        cellRenderer: params => <DateShort date={params.value} />,
        width: 150,
    }], [
        getInstallmentNumbers,
        getPolicy,
        multiFilter,
        parseDate,
        premiumColumnDefs,
        showCommission
    ]);

    const defaultColDef = useMemo(() => ({
        comparator: (valueA, valueB) => defaultComparator(
            valueA?.toLowerCase ? valueA.toLowerCase() : valueA,
            valueB?.toLowerCase ? valueB.toLowerCase() : valueB,
        ),
        enableRowGroup: true,
        filter: 'agTextColumnFilter',
        filterParams: {
            buttons: ['reset'],
        },
        floatingFilter: true,
        resizable: true,
        sortable: true,
    }), []);

    const rowClassRules = useMemo(() => ({
        'grid-row-danger': params => params.data && params.data.void,
        'progress-bar-striped': params => params.data && params.data.void,
    }), []);

    return (<Suspense fallback={<div className="p-3"><SmallSpinner /></div>}>
        <AgGridEnterprise>
            <AgGridReact ref={ref}
                         rowSelection={'multiple'}
                         rowMultiSelectWithClick={true}
                         className="ag-theme-alpine" {...{
                animateRows, columnDefs, defaultColDef, localeText, onGridReady, rowData, rowClassRules, excelStyles,
            }} />
        </AgGridEnterprise>
    </Suspense>);
});

PaymentGridWidget.propTypes = {
    rowData: PropTypes.arrayOf(PropTypes.object.isRequired),
};

export default PaymentGridWidget;
