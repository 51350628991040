import React, {useMemo, useState} from 'react';
import Content from '../../partials/Content.jsx';
import {
    useGetCurrentInsuranceBrokerQuery,
    useGetCurrentUserQuery,
    useLazyListPendingInstallmentsQuery,
    useLazyListPendingPoliciesQuery
} from "../../../features/apiSlice.js";
import {Col, Row, Table} from "react-bootstrap";
import WeekSelectionWidget from "../../widgets/Report/Selection/WeekSelectionWidget.jsx";
import DateRangeSelectionWidget from "../../widgets/Report/Selection/DateRangeSelectionWidget.jsx";
import Alert from "../../elements/ReactBootstrap/Alert.jsx";
import ErrorWidget from "../../widgets/ErrorWidget.jsx";
import Card from "../../elements/ReactBootstrap/Card.jsx";
import SmallSpinner from "../../elements/Spinner/SmallSpinner.jsx";
import DateShort from "../../elements/DateTime/DateShort.jsx";
import PremiumAmount from "../../elements/Values/PremiumAmount.jsx";
import PolicyInfoButton from "../../widgets/NavigationButtons/PolicyInfoButton.jsx";
import RequireRole from "../../elements/AccessControl/RequireRole.jsx";
import Select from "react-select";
import ErrorIcon from "../../elements/Icons/ErrorIcon.jsx";
import ExportToExcelButton from "../../elements/Buttons/ExportToExcelButton.jsx";
import {format} from "date-fns";

const PendingPayments = () => {

    const {
        data: user,
        isSuccess: userSuccess,
    } = useGetCurrentUserQuery();

    const {
        data: insuranceBroker,
        error: insuranceBrokerError,
        isLoading: insuranceBrokerIsLoading,
        isSuccess: insuranceBrokerIsSuccess,
        isError: insuranceBrokerIsError,
    } = useGetCurrentInsuranceBrokerQuery();

    const offices = useMemo(
        () => insuranceBrokerIsSuccess ? insuranceBroker.offices.filter(
            office => office.active
        ).map(office => ({
            value: office.id,
            label: 'Oфис ' + office.name,
        })).sort((a, b) => a.label.localeCompare(b.label)) : [],
        [insuranceBrokerIsSuccess, insuranceBroker]
    );

    const [type, setType] = useState("installments");
    const [office, setOffice] = useState(user.office.id);

    const pending = type === 'installments' ? useLazyListPendingInstallmentsQuery : useLazyListPendingPoliciesQuery;


    const [generate, {
        data: pendingPayments,
        error,
        isUninitialized,
        isLoading,
        isSuccess,
        isError,
    }] = pending();

    const dataForExport = isSuccess ? pendingPayments.map(pendingPayment => ({
        "Дата на сключване" : format(new Date(
            type === "policies" ? pendingPayment.issueDate : pendingPayment.policy.issueDate),
            'dd.MM.yyyy'),
        "Начална дата": format(new Date(
            type === "policies" ? pendingPayment.beginDate : pendingPayment.policy.beginDate),
            'dd.MM.yyyy'),
        "Крайна дата" : format(new Date(
            type === "policies" ? pendingPayment.endDate : pendingPayment.policy.endDate),
            'dd.MM.yyyy'),
        "Падеж" : type === "policies" ? '' : format(new Date(pendingPayment.dueDate), 'dd.MM.yyyy'),
        "ЗК" : type === "policies" ? pendingPayment.insuranceCompany.shortName : pendingPayment.policy.insuranceCompany.shortName,
        "Продукт" : type === "policies" ? pendingPayment.productName : pendingPayment.policy.productName,
        "Лице за контакт" : type === "policies" ?
            pendingPayment.contactClient?.name : pendingPayment.policy.contactClient?.name,
        "Телефон" : type === "policies" ?
            pendingPayment.contactClient?.mobilePhoneNumber : pendingPayment.policy.contactClient?.mobilePhoneNumber,
        "E-mail" : type === "policies" ?
            pendingPayment.contactClient?.email : pendingPayment.policy.contactClient?.email,
        "Застраховащ" : type === "policies" ?
            pendingPayment.policyHolder?.name : pendingPayment.policy.policyHolder?.name,
        "Телефон 1" : type === "policies" ?
            pendingPayment.policyHolder?.mobilePhoneNumber : pendingPayment.policy.policyHolder?.mobilePhoneNumber,
        "E-mail 1" : type === "policies" ?
            pendingPayment.policyHolder?.email : pendingPayment.policy.policyHolder?.email,
        "Застрахован" : type === "policies" ?
            pendingPayment.insuredClients?.[0]?.insuredClient?.name : pendingPayment.policy.insuredClients?.[0]?.insuredClient?.name,
        "Телефон 2" : type === "policies" ?
            pendingPayment.insuredClients?.[0]?.insuredClient?.mobilePhoneNumber : pendingPayment.policy.insuredClients?.[0]?.insuredClient?.mobilePhoneNumber,
        "E-mail 2" : type === "policies" ?
            pendingPayment.insuredClients?.[0]?.insuredClient?.email : pendingPayment.policy.insuredClients?.[0]?.insuredClient?.email,
    "МПС" : type === "policies" ? pendingPayment.insuredVehicle?.plateNumber : pendingPayment.policy.insuredVehicle?.plateNumber,
    "Марка" : type === "policies" ? pendingPayment.insuredVehicle?.brandName : pendingPayment.policy.insuredVehicle?.brandName,
    "Модел" : type === "policies" ? pendingPayment.insuredVehicle?.modelName : pendingPayment.policy.insuredVehicle?.modelName,
    "Сума" : type === "policies" ? '' : pendingPayment.dueAmount,
    "Корекция": type === "policies" ? '' : pendingPayment.policy.correctionCommissionCoefficient ?
        pendingPayment.dueAmount * pendingPayment.policy.correctionCommissionCoefficient : 0,
    "Вноска" : type === "policies" ? '' : pendingPayment.number + '/' + pendingPayment.policy.installmentsCount,
    "Полица": type === "policies" ? pendingPayment.number : pendingPayment.policy.number,
    "Консултант издател":  type === "policies" ?
        pendingPayment.issueUser?.shortName :
        pendingPayment.policy.issueUser?.shortName,
    "Консултант комисион": pendingPayment.commissionUser,
    "Офис издател": type === "policies" ?
        pendingPayment.issueOffice?.name :
        pendingPayment.policy.issueOffice?.name,
    })) : [];

    return (userSuccess && <Content title="Предстоящи плащания и изтичащи полици">
        <Card outline baseClassName="card-warning">
            <RequireRole name="ROLE_SHOW_BROKER">
                <Col className='mb-3 mt-3'>
                    {insuranceBrokerIsLoading && <SmallSpinner />}
                    {insuranceBrokerIsSuccess &&
                        <Select name='office'
                                options={offices}
                                value={offices ? offices.find(option => option.value === office) : ''}
                                onChange={option => setOffice(option.value)}
                        />}
                    {insuranceBrokerIsError && <ErrorIcon error={insuranceBrokerError} />}
                </Col>
            </RequireRole>
            <Row className="row-cols-1 row-cols-sm-2 gy-3">
                <Col>
                    <input type="radio" name="installments" checked={type === 'installments'} onChange={() =>
                        setType('installments')}/> Плащания за период
                </Col>
                <Col>
                    <input type="radio" name="policies" checked={type === 'policies'} onChange={() =>
                        setType('policies')}/> Изтичащи полици за период
                </Col>
                <Col>
                    <WeekSelectionWidget onSubmit={values => generate({office: office, ...values})} max={null}/>
                </Col>
                <Col>
                    <DateRangeSelectionWidget onSubmit={values => generate({office: office,...values})} max={null}/>
                </Col>
            </Row>
        </Card>
        <Row>
            <Col>
                {isUninitialized && <Alert variant="secondary" heading="Моля, изберете седмица или период." />}
                {isError && <ErrorWidget error={error} />}
                {isLoading && <Card>
                    <SmallSpinner />
                </Card>
                }
                {isSuccess && <Card>
                    <ExportToExcelButton data={dataForExport} fileName={'pending' + type} />
                    <Table striped className="mb-0">
                    <thead>
                        <tr>
                            <th>Дата на издаване</th>
                            <th>Начална дата</th>
                            <th>{type === "installments" ? "Падеж" : "Крайна дата"}</th>
                            <th>ЗК</th>
                            <th>Продукт</th>
                            <th>Полица</th>
                            <th>Лице за контакт</th>
                            <th>Телефон</th>
                            <th>E-mail</th>
                            <th>Застраховащ</th>
                            <th>Телефон</th>
                            <th>E-mail</th>
                            <th>Застрахован</th>
                            <th>Телефон</th>
                            <th>E-mail</th>
                            <th>МПС</th>
                            <th>Марка</th>
                            <th>Модел</th>
                            {type === "installments" && <>
                            <th>Вноска</th>
                            <th>Сума</th>
                            <th>Корекция</th>
                            </>}
                            <th>Консултант издател</th>
                            <th>Консултант комисион</th>
                            <th>Офис издател</th>
                        </tr>
                    </thead>
                    <tbody>
                    {pendingPayments.map(pendingPayment => {
                        const date = type === "installments"  ? pendingPayment.dueDate : pendingPayment.endDate;
                        const policy = type === "policies" ? pendingPayment : pendingPayment.policy;

                        if (!date || !policy) {
                            return null;
                        }

                        return (<tr key={pendingPayment.id}>
                            <td><DateShort date={policy.issueDate} /></td>
                            <td><DateShort date={policy.beginDate} /></td>
                            <td><DateShort date={date} /></td>
                            <td>{policy.insuranceCompany.shortName}</td>
                            <td>{policy.productName}</td>
                            <td><PolicyInfoButton policy={policy} /></td>
                            <td>{policy.contactClient?.name}</td>
                            <td>{policy.contactClient?.mobilePhoneNumber}</td>
                            <td>{policy.contactClient?.email}</td>
                            <td>{policy.policyHolder?.name}</td>
                            <td>{policy.policyHolder?.mobilePhoneNumber}</td>
                            <td>{policy.policyHolder?.email}</td>
                            <td>{policy.insuredClients?.[0]?.insuredClient?.name}</td>
                            <td>{policy.insuredClients?.[0]?.insuredClient?.mobilePhoneNumber}</td>
                            <td>{policy.insuredClients?.[0]?.insuredClient?.email}</td>
                            <td>{policy.insuredVehicle?.plateNumber}</td>
                            <td>{policy.insuredVehicle?.brandName}</td>
                            <td>{policy.insuredVehicle?.modelName}</td>
                            {type === "installments" && <>
                                <td>
                                    {pendingPayment.number} / {policy.installmentsCount}
                                </td>
                                <td><PremiumAmount amount={pendingPayment.dueAmount} /></td>
                                <td><PremiumAmount amount={policy.correctionCommissionCoefficient ?
                                    pendingPayment.dueAmount * policy.correctionCommissionCoefficient : 0} /></td>

                            </>}
                            <td>{policy.issueUser?.shortName}</td>
                            <td>{pendingPayment.commissionUser}</td>
                            <td>{policy.issueOffice?.name}</td>
                        </tr>);
                    })}
                </tbody>
                </Table></Card>}
            </Col>
        </Row>
    </Content>);
};
export default PendingPayments;
